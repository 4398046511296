
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&family=Roboto:wght@300;400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
    transition: ease all .5s;
}

body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.sticky-nav {
    animation: sticky-nav 300ms;
}

@keyframes sticky-nav {
    0% {
        transform: translateY(-6em)
    }

    100% {
        transform: translateY(0)
    }
}

.animated-image {
    transition: transform 0.5s ease-in-out;
  }
  
  .image-clicked {
    transform: translateX(-100%);
  }

  figcaption span {
    font-size: large;
    background-color: #F2EADE !important;
    border-radius: 10px;
    margin-bottom: 5px;
    color: #1C4D4A;
  }

  .musicIcon {
    transition: color 0.3s ease; /* You can adjust the transition properties as needed */
  }
  
  .musicIcon:hover {
    color: #ffbeff; /* Change this to the desired color during hover */
  }